export default {
  "corporate.title": `Company information`,
  "corporate.incorporationCode": `SIRET`,
  "corporate.incorporationDate": `Registration date`,
  "corporate.incorporationCountry": `Registration country`,
  "corporate.registeredName": `Registered name`,
  "corporate.legalType": `Form`,
  "corporate.industry": `Activity`,
  "corporate.accountPurposes": `Account purposes`,
  "corporate.vatNumber": `VAT number`,
  "corporate.expectedMonthlyExpenses": `Expected monthly expenses`,
  "corporate.employeesRange": `Number of employees`,

  "address.street": `Address`,
  "address.postalCode": `Postal code`,
  "address.city": `City`,
  "address.isoCountryCode": `Country`,
  "address.personal": `Home address`,

  "member.address.agree": `I certify that I am only a French tax resident`,
  "member.certify.information": `I certify on my honor the accuracy of the information`,
  "member.certify.usSpecifiedPerson": `I certify that I am not a "US Person"`,

  "member.subtitle.owner": "My right information",
  "member.direction.title": "Legal Representative Information",
  "member.director.appointment.date": "Date of appointment of director",
  "member.first.radio": "Director",
  "member.second.radio": "Agent",
  "member.form.gender": "Gender",
  "member.form.name": "Name",
  "member.form.surname": "First name",
  "member.form.nationality": "Nationality",
  "member.form.citizenShip": "Citizenship",
  "member.isDirector": "I am a Director / Manager",
  "member.identity.doc": `Identity document`,
  "member.identity.doc.type": `Identity document type`,
  "document.ubo.registry": "UBO Registry Document",
  "document.company.registry": "Company Registry Extract",
  "document.articles.association": "Articles of Company",
  "document.proof.address": "Proof of Address",

  "accountPurposes.pay.bills": `Pay Bills`,
  "accountPurposes.money.to.from.family": `Send/Receive Money to/from Family`,
  "accountPurposes.dividends": `Dividends`,
  "accountPurposes.savings": `Savings`,
  "accountPurposes.personal.business.purposes": `Personal Business Activities`,
  "accountPurposes.pension": `Pension`,
  "accountPurposes.crypto": `Crypto`,
  "accountPurposes.salary": `Salary`,
  "accountPurposes.lottery.gaming": `Lottery/Gaming`,
  "accountPurposes.reconciliation": `Reconciliation`,
  "accountPurposes.virtual.account": `Virtual Account`,
  "accountPurposes.exchange": `Currency Exchange`,
  "accountPurposes.other": `Other`,

  "get.id.header1": "🔒 Account Security",
  "get.id.text1": "To validate your account, we need to confirm your identity.",
  "get.id.header2": "📃 Quick Verification",
  "get.id.text2": "Have your ID ready and follow the steps.",
  "get.id.header3": "📩 A link for later",
  "get.id.text3.1":
    "If you can't verify now, you'll receive a link to the email you used.",
  "get.id.text3.2": "Complete the process later.",
  "get.id.text4": "Your account validation depends on it.",
  "get.id.button.verify": "🔹 Verify Now",
  "get.id.button.never": "⌛ Later",
  "get.id.text1.processing": "Your identity is being verified ⌛.",
  "get.id.text2.processing": `Click the "Next" button to finalize the creation of your account 🚀.`,
};
