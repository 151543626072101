export default {
  "maxdate.message": `Entrez une date valide`,

  "corporate.registeredName.empty": `Veuillez renseigner la raison sociale de l'entreprise`,
  "corporate.legalType.empty": `Veuillez renseigner la forme juridique de l'entreprise`,
  "corporate.industry.empty": `Veuillez renseigner l'activité de l'entreprise`,
  "corporate.accountPurposes.empty": `Veuillez indiquer l'utilisation du compte.`,
  "corporate.expectedMonthlyExpensesValue.empty": `Veuillez renseigner les dépenses mensuelles prévues de l'entreprise`,
  "corporate.incorporationDate.empty": `Veuillez renseigner la date de création de l'entreprise`,
  "corporate.incorporationCountry.empty": `Veuillez renseigner le pays de création de l'entreprise`,
  "corporate.incorporationCode.empty": `Veuillez renseigner le SIRET de l'entreprise`,
  "corporate.employeesRange.empty": `Veuillez renseigner le nombre de salariés de l'entreprise`,

  "address.postalCode.empty": `Veuillez renseigner le code postal`,
  "address.street.empty": `Veuillez renseigner l'adresse`,
  "address.isoCountryCode.empty": `Veuillez renseigner le pays`,
  "address.city.empty": `Veuillez renseigner la ville`,

  "onboarding.retrieving.information.error": `Erreur lors de la récupération des informations sur l'entreprise.`,

  "member.type.empty": `Veuillez choisir votre rôle dans l'entreprise`,
  "member.director.appointment.date.empty": `Veuillez renseigner votre date de nomination en tant que directeur`,
  "member.name.empty": `Veuillez renseigner votre nom`,
  "member.firstName.empty": `Veuillez renseigner votre prénom`,
  "member.nationality.empty": `Veuillez renseigner votre citoyenneté`,
  "member.usPerson.empty": `Cette case est obligatoire`,
  "member.gender.empty": `Veuillez renseigner votre genre`,
  "member.form.error.agree": `Veuillez certifier que vous êtes un résident fiscal en France.`,
  "member.error.accept": `Veuillez confirmer la véracité des informations renseignées.`,
  "member.email.already.exists": `Cette adresse email existe déjà, veuillez la changer.`,
  "member.phone.already.exists": `Cette numéro téléphone existe déjà, veuillez la changer.`,
  "member.phone.empty": `Veuillez renseigner votre numéro téléphone.`,
  "member.email.empty": `Veuillez renseigner votre adresse email.`,
  "member.doc.empty": `Veuillez remplir votre document`,
  "member.doc.type.empty": `Veuillez indiquer le type du document`,
  "member.error.deleteUser": `Erreur lors de la suppression de l'utilisateur.`,

  "create.corporate.error": `Erreur lors de la création de votre entreprise.`,
  "create.corporate.already.exists": `Cette entreprise existe déjà chez nous. Veuillez vérifier votre SIRET ou pays de création.`,
  "document.upload.incomplete":
    "Veuillez fournir un extrait de document du Registre des Sociétés (Extrait Kbis)",
  "file.upload.error": "Erreur de chargement du fichier. Veuillez réessayer.",

  "check.payment.stripe.status.error": `Erreur lors de la vérification du paiement des frais d'ouverture de votre compte.`,
  "create.payment.stripe.error": `Impossible de charger la page de paiement des frais d'ouverture de compte. Veuillez réessayer plus tard ou contacter le support technique si le problème persiste.`,
  "payment.stripe.not.paid": `Vous n'avez pas encore payé les frais de dossier pour l'ouverture de votre compte. Veuillez effectuer le paiement pour finaliser votre inscription.`,
  "create.get.id.error": `Une erreur est survenue lors de la vérification de votre identité.`,
};
