export default {
  maintenance: "Sapheer is in maintenance, come back in a few minutes",
  "maintenance.title": `Maintenance in progress`,
  "maintenance.subtitle1": `We are improving our platform`,
  "maintenance.subtitle2": `Come back in a few minutes`,
  "signin.title": "Connection ",
  "header.title": "Open your account in a few times",
  "company.title": "Information about the company",
  "direction.title": "Information about manager",
  "direction.title.liveness": `Information and identity verification`,
  "owner.title": "Information about owner",
  "owner.subtitle": "Descriptive text on whether or not to add owner",
  "owner.addButton": "Add",
  owner: "Owner",
  "button.previous": "previous",
  "button.next": "next",
  "server.error": "Server Error",
  "network.error": "Network Error",
  "upload.file.error": "Network error when uploading document",
  "other.error": "Connection error, Please verify your connection",
  upload: "Upload",
  edit: "Edit",
  complete: "Complete",
  "error.isNotAdult": "You should be a major person to create an account.",
  "error.upload": `Error while uploading file.`,
  "error.getIdentityDocument": `Error while getting identity document type.`,
  "error.getResidentDocument": `Error while getting address proof document type.`,
  "error.downloadingIdentityFile": `Error while downloading identity document.`,
  "error.downloadingAddressFile": `Error while downloading address proof document.`,
  "error.downloadFile": `Error while downloading file.`,
  "error.uploadOnlyOneFile": "Maximum file count reached",
  "error.uploadFileInvalid":
    "Invalid file type. Please choose an image or a pdf",
  "error.uploadTooLarge": "File too large.",
  "error.uploadTooSmall": "File too small.",
  delete: "Delete",
  save: "Save",
  "date.invalid": "Date format invalide",
  "date.maxdate.message":
    "You should be an adult person to be able to create an account.",
  "fileUpload.savedDocument": "Saved document:",
  cancel: "Cancel",
  "error.fileNotSelected": "Please select a file.",
  "error.nothingSelected": "This select field cannot be empty.",
  "error.notEmptyField": "This field cannot be empty.",
  or: "or",
  "person.checkbox.usSpecifiedPerson": `I certify that I am not a US Person`,

  "stepFive.form.empty.usPerson": "This checkbox is required",

  "phoneNumber.invalid":
    "Invalid phone number: The phone number must start with 00 or +",
  "phone.empty": "The phone number field is required",
  // ERROR CODE
  LEGALNAME_MAX_LENGTH_ERROR: "Your company name is longer than 20 characters.",
  COMPANY_ALREADY_VALIDATED:
    "This e-mail address is already being used by another user.",
  EMAIL_EXIST: "This e-mail address is already being used by another user.",
  "hosted.enough.document": `Host document not completed`,

  "cin.recto": `National Identity Card`,
  "cin.verso": `National Identity Card Verso (Optional)`,

  "identity.file.recto": `Identity document`,
  "identity.file.verso": `Identity document Verso (Optional)`,
  "identity2.file.recto": `Additional Vigilance`,
  "identity2.file.verso": `Additional Vigilance Verso (Optional)`,
  "identity3.file.recto": `Host identity document`,
  "identity3.file.verso": `Host identity document Verso (Optional)`,

  "common.error.emptySearch": "Oops !\nNo results match your search",
  "paiement.waiting": `Please waiting...`,
  legal_disclaimer: "Legal disclaimer",
  privacy_policy: "Privacy policy",
  "error.get.leader": `Error retrieving manager information`,
  "legal.mention": `Sapheer, brand operated by the company Spark, accounts and cards are issued by Paynovate under the MasterCard license and managed by LinkCy SAS. Paynovate is authorized by the National Bank of Belgium under the Electronic Money and Electronic Money Institutions Act (ref 0506763929) to issue electronic money and provide related payment services. LinkCy SAS is an agent of Paynovate, registered with NBB under number 852295732.`,
};
